// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-disabled {
  opacity: 0.75;
}

.card-content {
  border: 2px solid #071f3b;
  border-radius: 0px 0px 15px 15px;
}

.item-file {
  height: 100%;
  --border-radius: 10px;
}
.item-file div {
  width: 100%;
}

.subir-skeleton {
  --border-radius: 10px;
  --background: rgba(20, 135, 70, 0.882);
  --background-rgb: 20, 135, 70;
  border: 1px #2dd36f solid;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/form-validar-social/form-validar-social.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACE,yBAAA;EAEA,gCAAA;AAAF;;AAGA;EACE,YAAA;EACA,qBAAA;AAAF;AAKE;EACE,WAAA;AAHJ;;AAQA;EACE,qBAAA;EACA,sCAAA;EACA,6BAAA;EACA,yBAAA;AALF","sourcesContent":[".item-disabled{\n    opacity: 0.75;\n  }\n\n.card-content {\n  border: 2px solid #071f3b;\n  //border-style: none solid solid solid;\n  border-radius: 0px 0px 15px 15px;\n}\n\n.item-file {\n  height: 100%;\n  --border-radius: 10px;\n  // ion-label {\n  //   right: 5%;\n  //   margin-bottom: 0px;\n  // }\n  div {\n    width: 100%;\n  }\n  //display: block;\n}\n\n.subir-skeleton {\n  --border-radius: 10px;\n  --background: rgba(20, 135, 70, 0.882);\n  --background-rgb: 20, 135, 70;\n  border: 1px #2dd36f solid;\n  //height: 47px;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
