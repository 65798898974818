export const environment = {
  firebase: {
    projectId: 'login-promine',
    appId: '1:595296771743:web:30c9c401498d4dad398caa',
    storageBucket: 'login-promine.appspot.com',
    apiKey: 'AIzaSyBS1gBXFRUNLot1NCfWjDw0CfKmQqJ-SmQ',
    authDomain: 'login-promine.firebaseapp.com',
    messagingSenderId: '595296771743'
  },
  production: false,
  version:'1.0.5a'
};
