// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-disabled {
  opacity: 0.75;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/form-validar-psico/form-validar-psico.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF","sourcesContent":["\n.item-disabled{\n  opacity: 0.75;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
