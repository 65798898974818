// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-contenido {
  text-align: center;
}

.item-file {
  height: 100%;
  margin-bottom: 1rem;
  border-radius: 10px;
  border: 1px #b9b9b9 solid;
}
.item-file div {
  width: 100%;
}

.subir-skeleton {
  --border-radius: 10px;
  --background: rgba(20, 135, 70, 0.882);
  --background-rgb: 20, 135, 70;
  border: 1px #2dd36f solid;
}

.subir-label {
  position: absolute;
  align-items: center;
  opacity: 1 !important;
  font-style: italic;
  color: #422c02;
  margin-left: 20%;
}

.file-drag {
  display: contents;
  font-size: 16px;
  height: 100%;
  text-align: center;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/file-formdata/file-formdata.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;AACF;AAKE;EACE,WAAA;AAHJ;;AAQA;EACE,qBAAA;EACA,sCAAA;EACA,6BAAA;EACA,yBAAA;AALF;;AASA;EACE,kBAAA;EACA,mBAAA;EAEA,qBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;AAPF;;AAUA;EACE,iBAAA;EAIA,eAAA;EACA,YAAA;EAEA,kBAAA;EACA,mBAAA;AAXF","sourcesContent":[".form-contenido {\n  text-align: center;\n}\n\n.item-file {\n  height: 100%;\n  margin-bottom: 1rem;\n  border-radius: 10px;\n  border: 1px #b9b9b9 solid;\n\n  // ion-label {\n  //   right: 5%;\n  //   margin-bottom: 0px;\n  // }\n  div {\n    width: 100%;\n  }\n  //display: block;\n}\n\n.subir-skeleton {\n  --border-radius: 10px;\n  --background: rgba(20, 135, 70, 0.882);\n  --background-rgb: 20, 135, 70;\n  border: 1px #2dd36f solid;\n  //height: 47px;\n}\n\n.subir-label {\n  position: absolute;\n  align-items: center;\n  //display: inline-flex;\n  opacity: 1 !important;\n  font-style: italic;\n  color: #422c02;\n  margin-left: 20%;\n}\n\n.file-drag {\n  display: contents;\n  // align-items: center;\n  // justify-content: center;\n  // background-color: #ffc6099d ;\n  font-size: 16px;\n  height: 100%;\n  //padding: 20px;\n  text-align: center;\n  border-radius: 15px;\n  //z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
