// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-disabled {
  opacity: 0.75;
  background: #323232;
}

.lista-articulos {
  position: absolute;
  right: 5px;
  left: 0px;
  max-width: 100%;
  border-radius: 10px;
  border: 2px var(--ion-color-warning) solid;
  z-index: 9999;
}
.lista-articulos ion-item {
  font-size: 0.9rem;
  font-style: italic;
  --background-hover: var(--ion-color-warning);
  --background-hover-opacity:0.5;
}

.select-alert {
  background: #555;
  --width: 400px;
}

ion-fab-button.fab-mover {
  --background: var(--ion-color-light);
  --border-color: var(--ion-color-warning);
  --border-radius: 0px 0px 0px 25px;
  opacity: 0.5;
  --box-shadow: 0px 1px 2px 0px rgba(0,0,0,.3), 0px 1px 3px 1px rgba(0,0,0,.15);
  --color: black;
  width: 40px;
  height: 40px;
}

ion-fab-button.fab-mover:hover {
  opacity: 1;
  --background: var(--ion-color-warning);
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/form-validar-segu/form-validar-segu.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EAIA,UAAA;EACA,SAAA;EACA,eAAA;EACA,mBAAA;EACA,0CAAA;EACA,aAAA;AAFF;AAGE;EACE,iBAAA;EACA,kBAAA;EAEA,4CAAA;EACA,8BAAA;AAFJ;;AAOA;EACE,gBAAA;EACA,cAAA;AAJF;;AAOA;EACE,oCAAA;EACA,wCAAA;EAEA,iCAAA;EACA,YAAA;EAEA,6EAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AANF;;AASA;EACE,UAAA;EACA,sCAAA;AANF","sourcesContent":[".item-disabled {\n  opacity: 0.75;\n  background: #323232;\n}\n\n.lista-articulos {\n  position: absolute;\n  //display: block;\n  //opacity: 0.9;\n  // margin: 3rem;\n  right: 5px;\n  left: 0px;\n  max-width: 100%;\n  border-radius: 10px;\n  border: 2px var(--ion-color-warning) solid;\n  z-index: 9999;\n  ion-item{\n    font-size: 0.9rem;\n    font-style: italic;\n    //font-weight: bold;\n    --background-hover: var(--ion-color-warning);\n    --background-hover-opacity:0.5;\n  }\n\n}\n\n.select-alert{\n  background: #555;\n  --width: 400px;\n}\n\nion-fab-button.fab-mover {\n  --background: var(--ion-color-light);\n  --border-color: var(--ion-color-warning);\n  // --border-width: 3px;\n  --border-radius: 0px 0px 0px 25px;\n  opacity: 0.5;\n  // --background-hover-opacity:1;\n  --box-shadow: 0px 1px 2px 0px rgba(0,0,0,.3), 0px 1px 3px 1px rgba(0,0,0,.15);\n  --color: black;\n  width: 40px;\n  height: 40px;\n}\n\nion-fab-button.fab-mover:hover {\n  opacity: 1;\n  --background: var(--ion-color-warning);\n  //--border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
