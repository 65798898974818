// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar.item-avatar {
  width: 50px;
  height: 50px;
  margin-left: 0px;
}
ion-avatar.item-avatar ion-skeleton-text {
  --border-radius: 50px;
}

ion-spinner.item-spinner {
  border: #071f3b;
  opacity: 0.5;
  margin: 5px 5px 0px 0px;
  right: 0;
  position: absolute;
}

.list-loading {
  --background: #1366c5;
  --border-radius: 10px;
  --inner-border-width:0;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/loading-aspirante/loading-aspirante.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACI;EACI,qBAAA;AACR;;AAGA;EAGI,eAAA;EACA,YAAA;EACA,uBAAA;EACA,QAAA;EACA,kBAAA;AAFJ;;AAMA;EACI,qBAAA;EAEA,qBAAA;EACA,sBAAA;AAJJ","sourcesContent":["ion-avatar.item-avatar {\n    width: 50px;\n    height: 50px;\n    margin-left: 0px;\n    // margin-top: auto;\n    ion-skeleton-text{\n        --border-radius: 50px;\n    }\n}\n\nion-spinner.item-spinner {\n    //bottom: 0px;\n    //width: 40px;\n    border: #071f3b;\n    opacity: 0.5;\n    margin: 5px 5px 0px 0px;\n    right: 0;\n    position: absolute;\n    //margin-bottom: 10px;\n}\n\n.list-loading{\n    --background: #1366c5;\n    // --inner-box-shadow:none;\n    --border-radius: 10px;\n    --inner-border-width:0;\n    //padding: 0;\n    //padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
