// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-radio {
  width: 30px;
  height: 30px;
}

ion-textarea.custom-textarea {
  --background: #373737;
  --color: #fff;
  --padding-end: 20px;
  --padding-start: 20px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
}

.label-observacion {
  line-height: 15px;
  margin: 10px 0px;
  font-style: italic;
}

.transparente {
  --background: rgba(0, 0, 0, 0);
  --border-color: rgba(0, 0, 0, 0);
  text-align: center;
}

.item-disabled {
  opacity: 0.75;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/form-validar-legal/form-validar-legal.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAEE;EACE,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,yBAAA;EACA,0BAAA;AACJ;;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,8BAAA;EACA,gCAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,aAAA;AAAJ","sourcesContent":["ion-radio {\n    width: 30px;\n    height: 30px;\n  }\n  \n  ion-textarea.custom-textarea {\n    --background: #373737;\n    --color: #fff;\n    --padding-end: 20px;\n    --padding-start: 20px;\n    --placeholder-color: #ddd;\n    --placeholder-opacity: 0.8;\n    // height: 15em;\n  }\n   \n  .label-observacion {\n    line-height: 15px;\n    margin: 10px 0px;\n    font-style: italic;\n  }\n  \n  .transparente {\n    --background: rgba(0, 0, 0, 0);\n    --border-color: rgba(0, 0, 0, 0);\n    text-align: center;\n  }\n  \n  .item-disabled{\n    opacity: 0.75;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
