// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-cargo:focus-within {
  --background: var(--ion-color-warning);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/list-cargos/list-cargos.component.scss"],"names":[],"mappings":"AAEA;EAEI,sCAAA;EACA,mBAAA;AAFJ","sourcesContent":["\n\n.item-cargo:focus-within{\n    //background: rgb(228, 166, 33);\n    --background: var(--ion-color-warning);\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
