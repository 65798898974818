// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-content {
  border: 2px solid #071f3b;
  border-style: none solid solid solid;
  border-radius: 0px 0px 15px 15px;
}

.chip-list {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  height: -moz-fit-content;
  height: fit-content;
}

.chip-label {
  align-items: center;
  display: flex;
}

.chip-text {
  padding: 5px;
}

.item-textarea {
  font-size: 0.9rem;
  font-style: italic;
  padding: 0 !important;
}

.chip-close {
  height: -moz-fit-content;
  height: fit-content;
  --color: var(--ion-color-light);
  --color-hover: var(--ion-color-danger);
}

.button-ok {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/list-observacion/list-observacion.component.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,oCAAA;EACA,gCAAA;AADF;;AAIA;EACE,uBAAA;EAAA,kBAAA;EACA,UAAA;EACA,wBAAA;EAAA,mBAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;EACA,qBAAA;AADF;;AAKA;EAKE,wBAAA;EAAA,mBAAA;EACA,+BAAA;EACA,sCAAA;AANF;;AASA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AANF","sourcesContent":["//@use \"@angular/material/tooltip/styles\";\n\n.list-content {\n  border: 2px solid #071f3b;\n  border-style: none solid solid solid;\n  border-radius: 0px 0px 15px 15px;\n}\n\n.chip-list {\n  width: fit-content;\n  padding: 0;\n  height: fit-content;\n}\n\n.chip-label {\n  align-items: center;\n  display: flex;\n}\n\n.chip-text {\n  padding: 5px;\n}\n\n.item-textarea {\n  font-size: 0.9rem;\n  font-style: italic;\n  padding: 0 !important;\n  //height: fit-content !important;\n}\n\n.chip-close {\n  // --padding-end: 0;\n  // --padding-start: 10px;\n  // margin-right: 5px;\n  // width: auto;\n  height: fit-content;\n  --color: var(--ion-color-light);\n  --color-hover: var(--ion-color-danger);\n}\n\n.button-ok {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  width: 30px;\n  height: 30px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
