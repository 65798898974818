// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-disabled {
  opacity: 0.75;
}

ion-datetime {
  --background: var(--ion-color-light);
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/form-validar-medi/form-validar-medi.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF;;AAGA;EACE,oCAAA;EAGA,mBAAA;AAFF","sourcesContent":["\n.item-disabled{\n  opacity: 0.75;\n}\n\nion-datetime {\n  --background: var(--ion-color-light);\n  // --background-rgb: 255, 241, 242;\n\n  border-radius: 15px;\n  // box-shadow: rgba(var(--ion-color-primary), 0.3) 0px 10px 15px -3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
